@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;600&display=swap');

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80vh;
    min-width: 100vw;
    background-color: white;
    font-family: 'Raleway', sans-serif;
}

.title {
    font-size: 35px;
    margin: 45px;
    color: #03224c;
}

.titleWithUnderline {
    position: relative;
    display: inline-block;
}

.underline {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background: rgba(255, 165, 0, 0.55);
    transition: none;
    padding-bottom: 10px;
}

@keyframes growUnderline {
    from { width: 0; }
    to { width: 90%; }
}

.underlineAnimationVisible {
    animation: growUnderline 2s forwards;
}

.textActionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 90%;
    width: 90%;
    margin: 10px;
}

.textAction {
    color: #03224c;
    font-size: 20px;
    margin: 10px;
}

.actionsThemesWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-gap: 20px;
    height: 100%;
    width: 90%;
    margin: 10px;
    text-align: center;
    margin-bottom: 40px;
}

.actionsThemesItems {
    width: 100%;
    min-height: 400px;
    padding: 5px;
    background-color: white;
    border-radius: 10px;
    color: #03224c;
    border: 1px solid rgb(197, 134, 49);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
}

.actionsItemIcon {
    font-size: 25px;
    color: #03224c;
    margin-right: 15px;
}

.actionsItemTitle {
    font-size: 25px;
    color: #03224c;
    font-weight: bold;
    margin: 10px;
}

.actionsItemDescription {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    font-size: 20px;
    color: #03224c;
    margin: 10px;
    padding: 0;
    list-style-type: none;
}

.actionsItemIconCheck {
    font-size: 20px;
    color: rgb(197, 134, 49);
    margin-right: 15px;
}

.actionsItemDescription li {
    display: flex;
    align-items: center;
    align-content: center;
    font-size: 18px;
}

@media screen and (max-width: 1300px) {
    .title {
        font-size: 30px;
        margin-top: 45px;
    }
    .textAction {
        text-align: center;
        color: #03224c;
        font-size: 18px;
        margin: 10px;
    }
}

@media screen and (max-width: 1200px) {
    .actionsThemesWrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        grid-gap: 5px;
        height: 90%;
        width: 90%;
        margin: 10px;
        text-align: center;
    }
    .actionsItemIcon {
        font-size: 20px;
        margin-right: 15px;
    }
    .actionsItemTitle {
        font-size: 20px;
        font-weight: bold;
    }
    .actionsItemIconCheck {
        font-size: 18px;
        color: rgb(197, 134, 49);
        margin-right: 15px;
    }
    .actionsItemDescription {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
        font-size: 18px;
        margin: 10px;
        padding: 0;
        list-style-type: none;
    }
}

@media screen and (max-width: 1000px) {
    .title {
        font-size: 25px;
        margin-top: 45px;
    }
    .textAction {
        text-align: center;
        color: #03224c;
        font-size: 18px;
        margin: 10px;
    }
    .actionsThemesWrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        grid-gap: 5px;
        height: 90%;
        width: 90%;
        margin: 10px;
        text-align: center;
    }
}

