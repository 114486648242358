@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;600&display=swap');

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 75vh;
    min-width: 100vw;
    background-color: aliceblue;
    font-family: 'Raleway', sans-serif;
}

.title {
    font-size: 35px;
    margin: 45px;
    color: #03224c;
}

.titleWithUnderline {
    position: relative;
    display: inline-block;
}

.underline {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background: rgba(255, 165, 0, 0.55);
    transition: none;
    padding-bottom: 10px;
}

@keyframes growUnderline {
    from { width: 0; }
    to { width: 90%; }
}

.underlineAnimationVisible {
    animation: growUnderline 2s forwards;
}

.bothPartsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* min-height: 90vh; */
    min-width: 90vw;
}

.onePart1 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    width: 90%;
}

.onePart2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 90%;
}

.inPartContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 90%;
    width: 90%;
    margin: 10px;
}

.imgProfile {
    width: 80%;
    height: auto;
    border-radius: 15px;
}

.textPresentation {
    text-align: justify;
    color: #03224c;
    font-size: 20px;
    margin: 10px;
}

/* @media screen and (max-width: 1300px){
    .title {
        font-size: 30px;
        margin-top: 45px;
    }
    .bothPartsContainer {
        flex-direction: column;
    }
    .onePart1 {
        align-items: center;
    }
    .onePart2 {
        align-items: center;
    }
    .inPartContainer {
        flex-direction: column;
    }
    .imgProfile {
        margin-top: 15px;
        width: 70%;
        height: auto;
        border-radius: 15px;
    }
    .textPresentation {
        text-align: justify;
        color: #03224c;
        font-size: 18px;
        margin: 10px;
    }
} */

@media screen and (max-width: 1000px){
    .bothPartsContainer {
        flex-direction: column;
    }
    .onePart1 {
        align-items: center;
    }
    .onePart2 {
        align-items: center;
    }
    .inPartContainer {
        flex-direction: column;
    }
    .title {
        font-size: 30px;
        margin-top: 45px;
    }
    .imgProfile {
        margin-top: 15px;
        width: 80%;
        height: auto;
        border-radius: 15px;
    }
    .textPresentation {
        text-align: justify;
        color: #03224c;
        font-size: 16px;
        margin: 10px;
    }
}
