@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;600&display=swap');

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80vh;
    min-width: 100vw;
    background-color: aliceblue;
    font-family: 'Raleway', sans-serif;
}

.title {
    font-size: 35px;
    margin: 45px;
    color: #03224c;
}

.titleWithUnderline {
    position: relative;
    display: inline-block;
}

.underline {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background: rgba(255, 165, 0, 0.55);
    transition: none;
    padding-bottom: 10px;
}

@keyframes growUnderline {
    from { width: 0; }
    to { width: 90%; }
}

.underlineAnimationVisible {
    animation: growUnderline 2s forwards;
}

.lineContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative; /* Ajouté pour positionner le pseudo-élément */
}

.lineContainer1 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative; /* Ajouté pour positionner le pseudo-élément */
}

.lineContainer1::after {
    content: '';
    display: block;
    width: 60%;
    border-bottom: 1px solid rgba(3, 34, 76, 0.2);
    position: absolute;
    right: 5%; /* Aligner à droite */
    bottom: 0; /* Aligner au bas du conteneur */
}

.containerTitleAndTextAccompagnement {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.imageAccompagnement {
    width: 25%;
    height: 250px;
    object-fit: cover;
    border-radius: 20px;
}

.titleAccompagnement {
    font-size: 27px;
    margin-bottom: 15px;
    color: #03224c;
    font-weight: bold;
}

.textAccompagnement {
    width: 100%;
    font-size: 20px;
    text-align: justify;
}

@media screen and (max-width: 1300px) {
    .title {
        font-size: 30px;
        margin-top: 45px;
    }
    .titleAccompagnement {
        font-size: 22px;
        margin-bottom: 15px;
    }
    .textAccompagnement {
        font-size: 18px;
    }
}

@media screen and (max-width: 1000px) {
    .title {
        font-size: 25px;
        margin-top: 45px;
    }
    .lineContainer {
        flex-direction: column;
        align-items: center;
    }
    .lineContainer1 {
        flex-direction: column;
        align-items: center;
    }
    .lineContainer1::after {
        width: 90%;
    }
    .imageAccompagnement {
        width: 45%;
        height: auto;
        border-radius: 20px;
        margin-bottom: 25px;
    }
    .containerTitleAndTextAccompagnement {
        width: 80%;
    }
    .titleAccompagnement {
        font-size: 20px;
        margin-bottom: 15px;
    }
    .textAccompagnement {
        font-size: 16px;
        color: #03224c;
    }
}
