@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;600&display=swap');

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-image: url('../assets/bkghome2.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-family: 'Raleway', sans-serif;
}

.logoContainer {
    position: absolute;
    top: 30px;
    left: 30px;
}

.logo {
    width: 50%;
    display: flex;
}

.helloText {
    text-align: center;
    color: white;
    font-size: 30px;
    margin-top: 10px;
    font-weight: bold;
    width: 80%;
}

.homeButton {
    display: flex;
    margin-top: 85px;
    padding: 15px;
    border-radius: 10px;
    border: 2px solid aliceblue;
    font-size: 25px;
    color: aliceblue;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    transition: scale 0.3s ease;
}

.homeButton:hover {
    scale: 1.1;
}

.homeButton:active {
    scale: 0.9;
}

.arrowDownContainer {
    position: absolute;
    bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: aliceblue;
}

.arrowIcon {
    width: 45px;
    height: auto;
    cursor: pointer;
}

.buttonOurSolutionsFromAbout .arrowIcon {
    transition: transform 0.3s ease;
    animation: bounceAnimation 1s infinite;
}

@keyframes bounceAnimation {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0);
    }
}

@media screen and (max-width: 1300px) {
    .helloText {
        margin-top: 90px;
        font-size: 25px;
    }
    .arrowIcon {
        width: 35px;
        height: auto;
    }
    .logo {
        width: 50%;
    }
    .homeButton {
        font-size: 20px;
    }
    .arrowDownContainer {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .helloText {
        margin-top: 90px;
        font-size: 20px;
    }
    .arrowIcon {
        width: 25px;
        height: auto;
    }
    .logo {
        width: 50%;
    }
    .homeButton {
        font-size: 15px;
    }
    .arrowDownContainer {
        display: none;
    }
}
