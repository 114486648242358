@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;600&display=swap');

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 10vh;
    min-width: 100vw;
    background-color: #03224c;
    font-family: 'Raleway', sans-serif;
    color: aliceblue;
}

.bothContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-grow: 1;
}

.footerText1 {
    font-size: 20px;
    font-weight: 300;
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: aliceblue;
    font-weight: bold;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: transparent;
    border: none;
}

.footerText2 {
    font-size: 15px;
    font-weight: 300;
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: aliceblue;
    cursor: pointer;
}

.mentionsContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: auto;
    max-height: 80vh;
    overflow: auto;
    padding: 20px;
    margin: auto;
    background-color: white;
    color: #03224c;
    font-size: 16px;
    line-height: 1.6;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.returnButton {
    position: fixed;
    top: 0;
    right: 0;
    margin: 20px;
    padding: 10px 15px;
    border: none;
    background-color: #03224c;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    color: aliceblue;
    cursor: pointer;
    border-radius: 5px;
}
