@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;600&display=swap');

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-height: 80vh; */
    min-width: 100vw;
    background-color: aliceblue;
    font-family: 'Raleway', sans-serif;
}

.title {
    font-size: 35px;
    margin: 45px;
    color: #03224c;
}

.titleWithUnderline {
    position: relative;
    display: inline-block;
}

.underline {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background: rgba(255, 165, 0, 0.55);
    transition: none;
    padding-bottom: 10px;
}

@keyframes growUnderline {
    from { width: 0; }
    to { width: 90%; }
}

.underlineAnimationVisible {
    animation: growUnderline 2s forwards;
}

.textContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.textPresentation {
    /* text-align: left; */
    text-align: justify;
    color: #03224c;
    font-size: 20px;
    margin: 10px;
}

.stepsContainer {
    margin: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 95%;
}

.steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;
    padding: 7px;
    border-radius: 10px;
    background-color: white;
    margin: 0 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 165, 0, 0.55);
    transition: all 0.2s ease-in-out;
    margin-bottom: 15px;
    transform: translateY(20px);
    animation: slideUp 0.8s ease-out forwards;
}

.steps:hover {
    scale: 1.05;
    .stepsIcon {
        scale: 1.2;
    }
}

.lastSeparator {
    margin-bottom: 40px;
}

.stepsIcon {
    font-size: 28px;
    color: rgba(255, 165, 0, 0.9);
    margin: 10px;
    transition: all 0.2s ease-in-out;
}

.stepsTitle {
    font-weight: bolder;
    font-size: 16px;
    margin: 10px;
}

/* @media screen and (max-width: 1300px) {
    .title {
        font-size: 30px;
        margin-top: 45px;
    }
    .textPresentation {
        text-align: justify;
        color: #03224c;
        font-size: 18px;
        margin: 10px;
    }
    .stepsContainer {
        flex-direction: column;
        width: 100%;
    }
    .steps {
        width: 90%;
        padding: 5px;
        margin: 10px;
    }
    .stepsIcon {
        font-size: 25px;
    }
    .stepsTitle {
        font-size: 16px;
    }
} */

@media screen and (max-width: 1000px) {
    .title {
        font-size: 25px;
        margin-top: 45px;
    }
    .textPresentation {
        text-align: justify;
        color: #03224c;
        font-size: 16px;
        margin: 10px;
    }
    .stepsContainer {
        flex-direction: column;
        width: 100%;
    }
    .steps {
        width: 90%;
        padding: 5px;
        margin: 10px;
    }
    .stepsIcon {
        font-size: 25px;
    }
    .stepsTitle {
        font-size: 16px;
    }
}
