@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;600&display=swap');

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* min-height: 60vh; */
    min-width: 100vw;
    background-color: white;
    font-family: 'Raleway', sans-serif;
}

.title {
    font-size: 35px;
    margin: 45px;
    color: #03224c;
}

.titleWithUnderline {
    position: relative;
    display: inline-block;
}

.underline {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background: rgba(255, 165, 0, 0.55);
    transition: none;
    padding-bottom: 10px;
}

@keyframes growUnderline {
    from { width: 0; }
    to { width: 90%; }
}

.underlineAnimationVisible {
    animation: growUnderline 2s forwards;
}

.bothPartContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    justify-content: space-evenly;
    align-items: center;
}

.onePartContainer {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

.textContactUs {
    font-size: 20px;
    color: #03224c;
    text-align: center;
}

.infoContactContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding: 10px;
    gap: 30px;
}

.buttonContactUs {
    color: #03224c;
    border: none;
    border-radius: 15px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    /* margin-bottom: 40px; */
    text-decoration: none;
}

.buttonContactUs:hover {
    color: #011839;
}

.buttonContactUs:active {
    color: #000710;
}

.buttonTakeMeeting {
    background-color: #03224c;
    color: aliceblue;
    border: none;
    border-radius: 15px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 40px;
    text-decoration: none;
    padding: 20px;
    transition: 0.3s;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonTakeMeeting:hover {
    scale: 1.05;
}

.buttonTakeMeeting:active {
    scale: 0.95;
}

/* @media screen and (max-width: 1300px) {
    .title {
        font-size: 30px;
        margin-top: 45px;
    }
    .bothPartContainer {
        flex-direction: column;
    }
    .onePartContainer {
        width: 95%;
        margin-bottom: 40px;
    }
    .infoContactContainer {
        width: 90%;
    }
    .textContactUs {
        font-size: 18px;
    }
    .buttonContactUs {
        font-size: 16px;
    }
    .buttonTakeMeeting {
        width: 80%;
        font-size: 18px;
    }
} */

@media screen and (max-width: 1000px) {
    .title {
        font-size: 25px;
        margin-top: 45px;
    }
    .bothPartContainer {
        flex-direction: column;
    }
    .onePartContainer {
        width: 95%;
        margin-bottom: 40px;
    }
    .infoContactContainer {
        width: 90%;
    }
    .textContactUs {
        font-size: 18px;
    }
    .buttonContactUs {
        font-size: 16px;
    }
    .buttonTakeMeeting {
        width: 80%;
        font-size: 18px;
    }
}
